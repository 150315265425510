<template>
  <div class="header">
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题',
    },
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
}
</script>
<style scoped lang="scss">
.header {
  height: 77px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  background: url("../../assets/border-chart.jpg") no-repeat;
  background-size: 100% 100%;
  // background-color: red;
  .title {
    color: rgb(255,255,255);
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    font-family:"SourceHanSansSC";
  }

}
</style>
